import style from './index.module.scss';
import { Collapse, Image } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { REACT_APP_BASE_API_URL } from '@/constants';
import { CloseOutlined } from '@ant-design/icons';
import blobToBase64 from '@/utils';
import {
    queryDetail,
    queryDocument,
    queryDocumentContent,
    queryOnfidoContent,
} from '@/Api/kycList';
import cookie from 'react-cookies';
import {
    Page,
    Text,
    View,
    Document,
    PDFDownloadLink,
    StyleSheet,
    Image as PDFImage,
} from '@react-pdf/renderer';
import {
    queryCheckList,
    queryCheckInfo,
    queryOnfidoUploadList,
    queryOnfidoImgSrc,
    queryOnfidoUserPhotoList,
    queryOnfidoUserPhotoSrc,
} from '../../Api/kycList';
const pdfStyles = StyleSheet.create({
    body: { padding: '20px 40px' },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '50%',
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: 'auto',
        width: '100%',
        padding: '2px 10px',
        fontSize: 10,
        textAlign: 'left',
    },
    tableCellHeader: {
        margin: 'auto',
        width: '100%',
        padding: '2px 10px',
        fontSize: '12px',
        fontWeight: 600,
        textAlign: 'left',
        textTransform: 'capitalize',
    },
    tableCellContent: {
        margin: 'auto',
        width: '100%',
        padding: '2px 10px',
        fontSize: 10,
        fontWeight: 100,
        textAlign: 'left',
    },
    textDetail: { fontWeight: 300, width: '30%', fontSize: '12px' },
    resultDetailRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '10px',
        textTransform: 'capitalize',
    },
    resultDetailText: { fontSize: '12px', width: '70%', fontWeight: 400 },
});
function flattenObject(obj, result = {}, parentKey = '') {
    for (let key in obj) {
        let combinedKey = parentKey ? `${parentKey}.${key}` : key;
        if (Array.isArray(obj[key])) {
            obj[key].forEach((item, index) => {
                flattenObject(item, result, `${combinedKey}[${index}]`);
            });
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            flattenObject(obj[key], result, combinedKey);
        } else {
            result[combinedKey] = obj[key];
        }
    }
    return result;
}

function KycPDF({ detail, workflowRunId, checklistInfo, imgSrcList, photoSrcList, outerSrc }) {
    const info = detail?.customer_info;
    return (
        <Document style={{ width: '100vw' }}>
            <Page size={'A4'} style={pdfStyles.body}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <View
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '6px',
                        }}
                    >
                        <Text style={{ marginBottom: '10px' }}>
                            Form Sheet Online Identification
                        </Text>
                        <View style={pdfStyles.resultDetailRow}>
                            <Text style={pdfStyles.textDetail}>Onfido status</Text>
                            <Text style={{ ...pdfStyles.resultDetailText, color: 'green' }}>
                                {detail?.onfidoStatus}
                            </Text>
                        </View>
                        <View style={pdfStyles.resultDetailRow}>
                            <Text style={pdfStyles.textDetail}>Review status</Text>
                            <Text style={{ ...pdfStyles.resultDetailText, color: 'green' }}>
                                {detail?.reviewStatus}
                            </Text>
                        </View>
                        <View style={pdfStyles.resultDetailRow}>
                            <Text style={pdfStyles.textDetail}>Applicant id</Text>
                            <Text style={pdfStyles.resultDetailText}>{detail?.applicantId}</Text>
                        </View>
                        <View style={pdfStyles.resultDetailRow}>
                            <Text style={pdfStyles.textDetail}>WorkflowRun id</Text>
                            <Text style={pdfStyles.resultDetailText}>{detail?.workflowRunId}</Text>
                        </View>
                    </View>
                    {/* <View style={{ width: "20%" }}>
            <PDFImage style={{ width: "100%" }} src={"/img/yumi.png"}></PDFImage>
          </View> */}
                </View>
                <View style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Text style={{ fontSize: '14px' }}>Personal details</Text>
                </View>
                <View style={pdfStyles.table}>
                    <View style={pdfStyles.tableRow}>
                        <View style={{ ...pdfStyles.tableCol, width: '20%' }}>
                            <Text style={pdfStyles.tableCellHeader}>First name</Text>
                        </View>
                        <View style={{ ...pdfStyles.tableCol, width: '80%' }}>
                            <Text style={pdfStyles.tableCellContent}>{info?.firstName}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={{ ...pdfStyles.tableCol, width: '20%' }}>
                            <Text style={pdfStyles.tableCellHeader}>Last name</Text>
                        </View>
                        <View style={{ ...pdfStyles.tableCol, width: '80%' }}>
                            <Text style={pdfStyles.tableCellContent}>{info?.lastName} </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={{ ...pdfStyles.tableCol, width: '20%' }}>
                            <Text style={pdfStyles.tableCellHeader}>Email</Text>
                        </View>
                        <View style={{ ...pdfStyles.tableCol, width: '80%' }}>
                            <Text style={pdfStyles.tableCellContent}>{info?.email}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={{ ...pdfStyles.tableCol, width: '20%' }}>
                            <Text style={pdfStyles.tableCellHeader}>Date of birth</Text>
                        </View>
                        <View style={{ ...pdfStyles.tableCol, width: '80%' }}>
                            <Text style={pdfStyles.tableCellContent}>{info?.dateOfBirth}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={{ ...pdfStyles.tableCol, width: '20%' }}>
                            <Text style={pdfStyles.tableCellHeader}>Workflow Id</Text>
                        </View>
                        <View style={{ ...pdfStyles.tableCol, width: '80%' }}>
                            <Text
                                style={pdfStyles.tableCellContent}
                            >{`https://dashboard.onfido.com/results/${workflowRunId}`}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={{ ...pdfStyles.tableCol, width: '20%' }}>
                            <Text style={pdfStyles.tableCellHeader}>Country</Text>
                        </View>
                        <View style={{ ...pdfStyles.tableCol, width: '80%' }}>
                            <Text style={pdfStyles.tableCellContent}>{info?.address?.country}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={{ ...pdfStyles.tableCol, width: '20%' }}>
                            <Text style={pdfStyles.tableCellHeader}>State(US only)</Text>
                        </View>
                        <View style={{ ...pdfStyles.tableCol, width: '80%' }}>
                            <Text style={pdfStyles.tableCellContent}>-</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={{ ...pdfStyles.tableCol, width: '20%' }}>
                            <Text style={pdfStyles.tableCellHeader}>Phone number</Text>
                        </View>
                        <View style={{ ...pdfStyles.tableCol, width: '80%' }}>
                            <Text style={pdfStyles.tableCellContent}>{info?.phoneNumber}</Text>
                        </View>
                    </View>
                </View>

                <View>Checklist</View>
                {checklistInfo.map((i) => {
                    const flattened = flattenObject(i.properties);
                    const passed = i.result === 'clear';
                    return (
                        <>
                            <View
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    width: '80%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Text style={{ fontSize: '14px' }}>
                                    {i.name.split('_').join(' ')}
                                </Text>
                                <Text style={{ fontSize: '14px', color: passed ? 'green' : 'red' }}>
                                    {passed ? 'passed' : i.result}
                                </Text>
                            </View>
                            {
                                <View style={pdfStyles.table}>
                                    {Object.keys(flattened).map((o) => {
                                        return (
                                            <View style={pdfStyles.tableRow}>
                                                <View
                                                    style={{ ...pdfStyles.tableCol, width: '20%' }}
                                                >
                                                    <Text style={pdfStyles.tableCellHeader}>
                                                        {o.split('_').join(' ')}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={{ ...pdfStyles.tableCol, width: '80%' }}
                                                >
                                                    <Text style={pdfStyles.tableCellContent}>
                                                        {flattened[o]}{' '}
                                                    </Text>
                                                </View>
                                            </View>
                                        );
                                    })}
                                </View>
                            }
                        </>
                    );
                })}
            </Page>
            <Page size={'A4'} style={{ display: 'flex', padding: '20px' }}>
                {' '}
                <Text>{'Photo'}</Text>
                {photoSrcList.map((i) => {
                    return (
                        <View style={{ width: '100%', marginHorizontal: 'auto' }}>
                            <PDFImage src={i.replace('jpeg', 'png')}></PDFImage>
                        </View>
                    );
                })}
            </Page>
            <Page size={'A4'} style={{ display: 'flex', padding: '20px' }}>
                {Object.keys(imgSrcList).map((k) => {
                    return (
                        <View>
                            <Text>{k.split('_').join(' ')}</Text>
                            {imgSrcList[k].map((i) => (
                                <View style={{ width: '100%', marginHorizontal: 'auto' }}>
                                    <PDFImage src={i}></PDFImage>
                                </View>
                            ))}
                        </View>
                    );
                })}
            </Page>
            <Page size={'A4'} style={{ display: 'flex', padding: '20px' }}>
                {outerSrc.map((k) => {
                    return (
                        <View>
                            <Text>{k.label}</Text>

                            <View style={{ width: '100%', marginHorizontal: 'auto' }}>
                                <PDFImage src={k.src}></PDFImage>
                            </View>
                        </View>
                    );
                })}
            </Page>
        </Document>
    );
}
const toolbarOption = {
    zoomIn: 1,
    zoomOut: 1,
    oneToOne: 1,
    reset: 1,
    prev: 0, // 不显示
    play: false, // 不显示播放按钮
    next: 0, // 不显示
    rotateLeft: 1,
    rotateRight: 1,
    flipHorizontal: 1,
    flipVertical: 1,
};
const ApplicantDetailsModal = ({ visible, account, onClose, update }) => {
    const onChange = (key) => {
        console.log(key);
    };

    const [listOnfidoImg, setListOnfidoImg] = useState([]);
    const [currentImg, setCurrentImg] = useState('');
    const [userDetail, setUserDetails] = useState();

    const [checklistInfo, setChecklistInfo] = useState([]);
    const [imgSrcList, setImgSrcList] = useState({});
    const [photoSrcList, setPhotoSrcList] = useState([]);

    const [kycCheckListComplete, setKycChecklistComplete] = useState(false);
    const [kycUserPhotoComplete, setKycUserPhotoComplete] = useState(false);
    const [kycUploadFileComplete, setKycUploadFileComplete] = useState(false);
    const [outerSrc, setOuterSrc] = useState([]);
    const [outerComplete, setOuterComplete] = useState(false);

    useEffect(() => {
        if (outerComplete) {
            const image1 = document.getElementById(`img-ref-1`);
            console.debug('🚀 ~ useEffect ~ image1:', image1);
            const viewer1 =
                image1 &&
                new window.Viewer(image1, {
                    inline: false, // 可以根据需要设置为false
                    toolbar: toolbarOption,
                    viewed: function () {
                        viewer1.zoomTo(1); // 初始化时的缩放级别
                    },
                });
            const image2 = document.getElementById(`img-ref-2`);
            const viewer2 =
                image2 &&
                new window.Viewer(image2, {
                    inline: false, // 可以根据需要设置为false
                    toolbar: toolbarOption,
                    viewed: function () {
                        viewer2.zoomTo(1); // 初始化时的缩放级别
                    },
                });
            const image3 = document.getElementById(`img-ref-3`);
            const viewer3 =
                image3 &&
                new window.Viewer(image3, {
                    inline: false, // 可以根据需要设置为false
                    toolbar: toolbarOption,
                    viewed: function () {
                        viewer3.zoomTo(1); // 初始化时的缩放级别
                    },
                });
        }
    }, [outerComplete]);
    const getDetails = useCallback(() => {
        queryDetail(account)
            .then((res) => {
                setUserDetails(res);
                let { onfido_docs } = res;
                if (onfido_docs) {
                    let { documents } = onfido_docs;
                    let _imgList = [];
                    Promise.all(
                        documents.map((item) => {
                            // 获取onfido 上传图片
                            return queryOnfidoContent(item.id).then((onfido_img) => {
                                let blob = new Blob([onfido_img], { type: 'img/jpeg' });
                                const imgUrl = URL.createObjectURL(blob);
                                return _imgList.push(imgUrl);
                            });
                        }),
                    ).then((list) => {
                        setCurrentImg(_imgList[0] || '');
                        setListOnfidoImg(_imgList); //
                    });
                }
            })
            .catch((err) => {
                console.log(err, 'err');
            });
        //获取用户上传信息
        queryDocument(account).then((res) => {
            // return;
            let queryLabel = document.getElementsByName('label');
            Promise.all(
                res.files_detail
                    .slice(0, 3)
                    // eslint-disable-next-line array-callback-return
                    .map((item, index) => {
                        queryLabel[index].innerHTML = item.label;
                        if (item.url.split('/')[1].indexOf('.pdf') !== -1) {
                            const imgrender = document.createElement('div');
                            console.debug('🚀 ~ .map ~ imgrender:', imgrender);
                            imgrender.style.wordBreak = 'break-word';
                            imgrender.style.cursor = 'pointer';
                            let url = `${REACT_APP_BASE_API_URL}admin/getKycDocumentContent?doc_id=${
                                item.url.split('/')[1]
                            }&customer_id=${item.url.split('/')[0]}`;
                            // imgrender.href = url;
                            imgrender.innerHTML = url;
                            imgrender.onclick = (e) => {
                                e.preventDefault();
                                const headers = new Headers({
                                    Authorization: `Bearer ${
                                        cookie.load('kyc-admin-user').jwtToken
                                    }`,
                                    'Content-Type': 'application/json',
                                });
                                const request = new Request(url, { headers });
                                fetch(request)
                                    .then((response) => {
                                        return response.blob();
                                    })
                                    .then((blob) => {
                                        let newblob = new Blob([blob], { type: 'application/pdf' });
                                        blobToBase64(newblob).then((r) => {
                                            setOuterSrc((p) => [
                                                ...p,
                                                { label: item.label, src: r },
                                            ]);
                                        });
                                        const url = window.URL.createObjectURL(newblob);
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', `${item.url.split('/')[1]}`);
                                        document.body.appendChild(link);
                                        link.click();
                                        link.remove();
                                        // 处理响应
                                        return 'ok';
                                    })
                                    .catch((error) => {
                                        console.log(error, 'error');
                                        // 处理错误
                                    });
                                // window.open(url);
                            };
                            let captchaImg = document.getElementById(`show-img${index + 1}`);
                            imgrender && captchaImg.appendChild(imgrender);
                        } else {
                            return queryDocumentContent(
                                item.url.split('/')[1],
                                item.url.split('/')[0],
                            ).then((img) => {
                                let blob = new Blob([img], { type: 'img/jpeg' });
                                const imgUrl = URL.createObjectURL(blob);
                                blobToBase64(blob).then((r) => {
                                    setOuterSrc((p) => [...p, { label: item.label, src: r }]);
                                });
                                const imgrender = document.createElement('img');
                                imgrender.src = imgUrl;
                                imgrender.id = `img-ref-${index + 1}`;
                                let captchaImg = document.getElementById(`show-img${index + 1}`);
                                imgrender && captchaImg.appendChild(imgrender);
                            });
                        }
                    }),
            ).finally(() => {
                setOuterComplete(true);
            });
        });
    }, [account]);

    useEffect(() => {
        getDetails();
    }, [account, getDetails]);

    useEffect(() => {
        userDetail &&
            queryCheckList(userDetail?.applicantId).then((list) => {
                if (list.length > 0) {
                    Promise.all(list.map((i) => queryCheckInfo(i))).then((r) => {
                        setChecklistInfo(r);
                        setKycChecklistComplete(true);
                    });
                } else {
                    setKycChecklistComplete(true);
                }
            });
        userDetail &&
            queryOnfidoUploadList(userDetail?.applicantId).then((res) => {
                if (Object.keys(res).length === 0) {
                    setKycUploadFileComplete(true);
                } else {
                    return Object.keys(res).map((k) =>
                        Promise.all(res[k].map((i) => queryOnfidoImgSrc(i.id))).then((list) => {
                            setImgSrcList((p) => ({ ...p, [k]: list }));
                            setKycUploadFileComplete(true);
                        }),
                    );
                }
            });

        userDetail &&
            queryOnfidoUserPhotoList(userDetail?.applicantId).then((list) => {
                console.debug('🚀 ~ file: index.jsx:413 ~ queryOnfidoUserPhotoList ~ list:', list);
                if (list.length > 0) {
                    Promise.all(list.map((i) => queryOnfidoUserPhotoSrc(i))).then((s) => {
                        setPhotoSrcList((p) => [...p, ...s]);
                        setKycUserPhotoComplete(true);
                    });
                } else {
                    setKycUserPhotoComplete(true);
                }
            });
    }, [userDetail]);
    return (
        <div className={`${style.applicantModal} ${visible ? style.open : ''}`}>
            <div className="modal">
                <div className="close" onClick={() => onClose()}>
                    <CloseOutlined />
                </div>

                <Collapse
                    defaultActiveKey={['Applicant']}
                    onChange={onChange}
                    expandIconPosition={'end'}
                    style={{ marginBottom: '15px', marginTop: '12px', backgroundColor: '#fff' }}
                >
                    <Collapse.Panel header="Applicant details" key="Applicant">
                        <div className="applicant">
                            <div className="left">
                                <div className="first-name">
                                    <div className="label">First name</div>
                                    <div className="value">
                                        {userDetail?.customer_info.firstName}
                                    </div>
                                </div>
                                <div className="last-name">
                                    <div className="label">Last name</div>
                                    <div className="value">
                                        {userDetail?.customer_info.lastName}
                                    </div>
                                </div>
                                <div className="email">
                                    <div className="label">Email</div>
                                    <div className="value">{userDetail?.customer_info.email}</div>
                                </div>
                                <div className="data">
                                    <div className="label">Date of birth</div>
                                    <div className="value">
                                        {userDetail?.customer_info.dateOfBirth}
                                    </div>
                                </div>
                                <div className="data">
                                    <div className="label">Workflow Id</div>
                                    <a
                                        className="value"
                                        href={`https://dashboard.onfido.com/results/${userDetail?.workflowRunId}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://dashboard.onfido.com/results/
                                        {userDetail?.workflowRunId}
                                    </a>
                                </div>
                            </div>
                            <div className="right">
                                {/* <div className="address">
                  <div className="label">Address</div>
                  <div className="value">
                    <div>Cuihu Street,Huangpu Road</div>
                    <div>Room 402,Building 5</div>
                    <div>510000</div>
                    <div>China(CHN)</div>
                  </div>
                </div> */}
                                <div className="Country">
                                    <div className="label">Country</div>
                                    <div className="value">
                                        {userDetail?.customer_info.address?.country}
                                    </div>
                                </div>
                                <div className="state">
                                    <div className="label">State(US only)</div>
                                    <div className="value">-</div>
                                </div>
                                <div className="Phone">
                                    <div className="label">Phone number</div>
                                    <div className="value">
                                        {userDetail?.customer_info.phoneNumber}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse.Panel>
                </Collapse>
                <Collapse
                    onChange={onChange}
                    expandIconPosition={'end'}
                    style={{ marginBottom: '15px', backgroundColor: '#fff' }}
                >
                    <Collapse.Panel
                        header={<div>Document report with address information</div>}
                        expandIconPosition={'end'}
                    >
                        <div className="img-cover">
                            <div className="img-list">
                                {listOnfidoImg.map((item) => {
                                    return (
                                        <div
                                            className="cell-img"
                                            onClick={() => setCurrentImg(item)}
                                        >
                                            <img src={item} alt="" />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="cover">
                                <div className="cover-top">
                                    <Image src={currentImg} />
                                </div>
                            </div>
                        </div>
                    </Collapse.Panel>
                </Collapse>

                <div className="bottom-image">
                    <div className="show">
                        <div className="label" name="label"></div>
                        <div
                            className="show-img"
                            id="show-img1"
                            style={{ cursor: 'pointer' }}
                        ></div>
                    </div>
                    <div className="show">
                        <div className="label" name="label"></div>
                        <div
                            className="show-img"
                            id="show-img2"
                            style={{ cursor: 'pointer' }}
                        ></div>
                    </div>
                    <div className="show">
                        <div className="label" name="label" style={{ marginBottom: '20px' }}>
                            Explanation of ICP acquisition
                        </div>
                        <div className="text">{userDetail?.explanationICP}</div>
                        <div className="show-img" id="show-img3" style={{ cursor: 'pointer' }}>
                            {/* <img id="captchaImg2" src={""} alt="" /> */}
                        </div>
                    </div>
                    <div className="btn">
                        <div
                            onClick={() => {
                                update();
                                onClose();
                            }}
                        >
                            Save
                        </div>
                        {kycUploadFileComplete &&
                            kycCheckListComplete &&
                            outerComplete &&
                            kycUserPhotoComplete && (
                                <PDFDownloadLink
                                    document={
                                        <KycPDF
                                            detail={userDetail}
                                            workflowRunId={userDetail?.workflowRunId}
                                            checklistInfo={checklistInfo}
                                            imgSrcList={imgSrcList}
                                            photoSrcList={photoSrcList}
                                            outerSrc={outerSrc}
                                        />
                                    }
                                    fileName={
                                        userDetail?.principal_id +
                                        '_' +
                                        new Date(Date.now()).toUTCString()
                                    }
                                >
                                    export as PDF
                                </PDFDownloadLink>
                            )}
                        {/* {photoSrcList.map((i) => {
              return <img src={i}></img>;
            })} */}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ApplicantDetailsModal;
